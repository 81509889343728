<template>
   <div class="cont">
      <el-row class="content-box">
         <el-row>
            <el-link type="primary" class="back_iconfont" icon="iconfont iconfanhui1" @click="go_back()">
               <span v-text="macAddressVal" style="font-size: 20px;"></span>
            </el-link>
            <span class="box-span mini bg-green" v-if="onlineStatusVal === 'ONLINE'"></span>
            <span class="box-span mini bg-red" v-else-if="onlineStatusVal === 'OFFLINE'"></span>
            <span class="box-span mini bg-gray" v-else></span>
            <div class="header-right">
               <p v-text="userName" class="box-pad"></p>
            </div>
         </el-row>
         <!-- 设备详情 -->
         <div class="det-box">
            <el-divider content-position="left"><span v-text="$t('msg.dev_info')">设备信息</span></el-divider>
            <ul>
               <li>
                  <span v-text="$t('msg.dev_product_a')">所属产品：</span>
                  <span v-text="devProductVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.dev_type_a')">设备类型：</span>
                  <span v-if="devTypeVal === '—'" v-text="$t('msg.gateway')">网关</span>
                  <span v-else v-text="devTypeVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.format_a')">规格：</span>
                  <span v-text="formatVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.dev_model_a')">设备型号：</span>
                  <span v-text="devModelVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.dev_key_a')">设备Key：</span>
                  <span v-text="devKeyVal">—</span>
               </li>
               <li style="overflow: hidden;">
                  <span v-text="$t('msg.dev_secret_a')">设备秘钥：</span>
                  <span v-text="devSecretVal">—</span>
               </li>
               <li v-if="deviceTypeId !== '100'">
                  <span v-text="$t('msg.device_version_a')">设备版本：</span>
                  <span v-text="devVersionVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.product_description_a')">型号描述：</span>
                  <span v-text="descriptionVal">—</span>
               </li>
               <!-- <li>
               <span v-text="$t('msg.mac_address_a')">MAC地址：</span>
               <span v-text="macAddressVal">—</span>
               </li> -->
               <li v-if="deviceTypeId === '100'">
                  <span v-text="$t('msg.gateway_version_a')">网关版本：</span>
                  <!-- <span v-if="ownGatewayVal === '—'" v-text="ownGatewayVal">—</span> -->
                  <span v-text="GatewayVersion">—</span>
               </li>
               <li v-if="deviceTypeId !== '100'">
                  <span v-text="$t('msg.own_gateway_a')">所属网关：</span>
                  <span v-if="ownGatewayVal !== '—'" v-text="ownGatewayVal" class="gateway_url" @click="gateway_url()">—</span>
                  <span v-else v-text="ownGatewayVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.dev_alias_a')">设备别名：</span>
                  <span v-text="devAliasVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.install_location_a')">安装位置：</span>
                  <span v-text="locationVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.agreement_type_a')">协议类型：</span>
                  <span v-text="agreementVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.internet_time')">入网时间：</span>
                  <span v-text="addTimeVal">—</span>
               </li>
               <!-- <li>
               <span v-text="$t('msg.online_status_a')">在线状态：</span>
               <span style="color: #67c23a;" v-if="onlineStatusVal === 'ONLINE'" v-text="$t('msg.online')">在线</span>
               <span style="color: #F56C6C;" v-else-if="onlineStatusVal === 'OFFLINE'" v-text="$t('msg.offline')">离线</span>
               <span style="color: #9797A2;" v-else v-text="$t('msg.inactivated')">未激活</span>
               </li> -->
               <li>
                  <span v-text="$t('msg.last_online_time_a')">最后在线时间：</span>
                  <span v-text="lastTimeVal">—</span>
               </li>
               <li>
                  <span v-text="$t('msg.last_offline_time')">最后离线时间：</span>
                  <span v-text="offTimeVal">—</span>
               </li>
               <li v-if="productId === ('HODSZB' || 'HOMSZB' || 'HOGSZB' || 'HOSAZB')">
                  <span v-text="$t('msg.push_messages_a')">是否推送消息：</span>
                  <span v-if="pushMessagesVal === 'YES'" v-text="$t('msg.yes')">是</span>
                  <span v-if="pushMessagesVal === 'NO'" v-text="$t('msg.no')">否</span>
               </li>
               <li v-if="deviceAttach.currentLevel">
                  <span v-text="$t('msg.panel_brightness_a')">面板亮度：</span>
                  <span v-text="deviceAttach.currentLevel" class="panel-box">—</span>
                  <span v-text="'——'" class="m-right-10"></span>
                  <span v-text="deviceAttach.onLevel" class="panel-box panel-box2">—</span>
               </li>
               <li v-if="curDirectionVal">
                  <span v-text="$t('msg.cur_direction_a')">窗帘转动方向：</span>
                  <span v-if="curDirectionVal === 'FRONT'" v-text="$t('msg.positive')">正</span>
                  <span v-if="curDirectionVal === 'REVERSE'" v-text="$t('msg.anti')">反</span>
               </li>
               <li v-if="wheNormalVal">
                  <span v-text="$t('msg.whe_normal_a')">电量是否正常：</span>
                  <span v-if="wheNormalVal === 'NORMAL'" v-text="$t('msg.normal')">正常</span>
                  <span v-if="wheNormalVal === 'UNNORMAL'" v-text="$t('msg.abnormal')">异常</span>
               </li>
               <li v-if="whePowerVal">
                  <span v-text="$t('msg.whe_power_a')">是否推送电量：</span>
                  <span v-if="whePowerVal === 'YES'" v-text="$t('msg.yes')">是</span>
                  <span v-if="whePowerVal === 'NO'" v-text="$t('msg.no')">否</span>
               </li>
            </ul>
         </div>
         <!-- 设备按键列表 -->
         <div class="det-box" v-if="devData.length > 0">
            <el-divider content-position="left"><span v-text="$t('msg.device_list')">设备按键列表</span></el-divider>
            <el-table :data="devData" style="width: 100%" min-height="780" max-height="780" :stripe="true" >
               <el-table-column type="index" :label="$t('msg.key_position')" width="300"></el-table-column>
               <el-table-column prop="key_type" :label="$t('msg.key_type')">
                  <template slot-scope="scope">
                     <span v-if="scope.row.key_type === 'SWITCH'" v-text="$t('msg.switch')">开关</span>
                     <span v-else-if="scope.row.key_type === 'SCENE'" v-text="$t('msg.scene')">场景</span>
                     <span v-else v-text="scope.row.key_type"></span>
                  </template>
               </el-table-column>
               <el-table-column prop="key_alias" :label="$t('msg.key_alias')"></el-table-column>
               <el-table-column prop="key_status" :label="$t('msg.key_status')">
                  <template slot-scope="scope">
                     <span v-if="scope.row.key_status === 'TURNON'"><i class="el-icon-check" style="color: #00BFBF;font-size: 20px;"></i></span>
                  </template>
               </el-table-column>
               <el-table-column prop="whe_connect" :label="$t('msg.whe_connect')">
                  <template slot-scope="scope">
               <span v-if="scope.row.whe_connect === 'YES'">
               <i class="iconfont icondeng-copy" style="color: #F59A23;"></i>
               </span>
                  </template>
               </el-table-column>
            </el-table>
         </div>
         <!-- 温控面板参数列表 -->
         <div class="det-box" v-if="controlData.length > 0">
            <el-divider content-position="left"><span v-text="$t('msg.control_list')">温控面板参数列表</span></el-divider>
            <el-table :data="controlData" style="width: 100%" min-height="780" max-height="780" :stripe="true" >
               <el-table-column type="index" :label="$t('msg.number')" width="70"></el-table-column>
               <el-table-column prop="mode" :label="$t('msg.mode')">
                  <template slot-scope="scope">
                     <span v-if="scope.row.mode === 'COOL'" v-text="$t('msg.refrigeration')">制冷</span>
                     <span v-else-if="scope.row.mode === 'HOT'" v-text="$t('msg.heating')">制热</span>
                     <span v-else v-text="scope.row.mode"></span>
                  </template>
               </el-table-column>
               <el-table-column prop="switch_statusr" :label="$t('msg.switch_statusr')">
                  <template slot-scope="scope">
                     <span v-if="scope.row.switch_statusr === 'TURNON'"><i class="el-icon-check" style="color: #00BFBF;font-size: 20px;"></i></span>
                     <!-- <span v-if="scope.row.switch_statusr === 'TURNON'" v-text="$t('msg.open_s')">开</span>
                     <span v-else-if="scope.row.switch_statusr === 'TURNOFF'" v-text="$t('msg.close_s')">关</span>
                     <span v-else v-text="scope.row.switch_statusr"></span> -->
                  </template>
               </el-table-column>
               <el-table-column prop="lock_status" :label="$t('msg.lock_status')">
                  <template slot-scope="scope">
                     <span v-if="scope.row.lock_status === 'TURNON'"><i class="el-icon-check" style="color: #00BFBF;font-size: 20px;"></i></span>
                     <!-- <span v-if="scope.row.lock_status === 'TURNON'" v-text="$t('msg.open_s')">开</span>
                     <span v-else-if="scope.row.lock_status === 'TURNOFF'" v-text="$t('msg.close_s')">关</span>
                     <span v-else v-text="scope.row.lock_status"></span> -->
                  </template>
               </el-table-column>
               <el-table-column prop="current_temperature" :label="$t('msg.current_temperature')"></el-table-column>
               <el-table-column prop="target_temperature" :label="$t('msg.target_temperature')"></el-table-column>
               <el-table-column prop="wind_speed" :label="$t('msg.wind_speed')">
                  <template slot-scope="scope">
                     <span v-if="scope.row.wind_speed === 'LOW'" v-text="$t('msg.low')">低</span>
                     <span v-else-if="scope.row.wind_speed === 'MIDDLE'" v-text="$t('msg.middle')">中</span>
                     <span v-else-if="scope.row.wind_speed === 'HIGH'" v-text="$t('msg.high')">高</span>
                     <span v-else-if="scope.row.wind_speed === 'AUTO'" v-text="$t('msg.auto')">自动</span>
                     <span v-else v-text="scope.row.wind_speed"></span>
                  </template>
               </el-table-column>
               <el-table-column prop="time_on_time" :label="$t('msg.time_on_time')"></el-table-column>
               <el-table-column prop="time_off" :label="$t('msg.time_off')"></el-table-column>
               <el-table-column prop="reporting_time" :label="$t('msg.reporting_time')" min-width="150" sortable></el-table-column>
            </el-table>
         </div>
         <!-- 红外宝子设备 -->
         <div class="det-box" v-if="baoziData.length > 0">
            <el-divider content-position="left"><span v-text="$t('msg.baozi_equipment')">红外宝子设备</span></el-divider>
            <el-table :data="baoziData" style="width: 100%" min-height="780" max-height="780" :stripe="true" >
               <el-table-column type="index" :label="$t('msg.number')" width="70"></el-table-column>
               <el-table-column prop="dev_type" :label="$t('msg.dev_type')" min-width="110"></el-table-column>
               <el-table-column prop="dev_number" :label="$t('msg.dev_number')" min-width="110"></el-table-column>
               <el-table-column prop="brand_name" :label="$t('msg.brand_name')" min-width="150"></el-table-column>
               <el-table-column prop="brand_id" :label="$t('msg.brand_id')"></el-table-column>
               <el-table-column prop="brand_model" :label="$t('msg.brand_model')"></el-table-column>
               <el-table-column prop="add_time" :label="$t('msg.add_time')" min-width="150" sortable></el-table-column>
               <el-table-column :label="$t('msg.operating')" min-width="90" fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" icon="el-icon-position" @click="info(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.instruction')">指令</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </div>
         <!-- 查看指令 -->
         <el-form label-width="105px">
            <el-dialog :title="$t('msg.infrared_list')" :visible.sync="instruction" width="670px">
               <el-table :data="infraredData" style="width: 100%" :stripe="true" max-height="530">
                  <el-table-column property="keyName" :label="$t('msg.key_name')"></el-table-column>
                  <el-table-column property="keyVal" :label="$t('msg.key_val')"></el-table-column>
                  <el-table-column property="resultCmd" :label="$t('msg.result_cmd')" min-width="150"></el-table-column>
               </el-table>
            </el-dialog>
         </el-form>
         <!-- 状态图表 -->
         <div class="ech-box">
            <div class="ech-item">
               <el-link type="primary" @click="more_status()" v-text="$t('msg.more_status')">更多状态</el-link>
               <div class="txt-box">
                  <p class="c-blue" v-text="$t('msg.online')">在线</p>
                  <p class="c-orange" v-text="$t('msg.offline')">离线</p>
               </div>
               <div id="status-ech"></div>
               <p class="hour-box c-blue" v-text="$t('msg.hour')">小时</p>
            </div>
         </div>
         <!-- 更多状态 -->
         <el-form label-width="105px">
            <el-dialog :title="$t('msg.status_list')" :visible.sync="moreStatus" width="670px">
               <el-row>
                  <span class="label" v-text="$t('msg.time_limit_a')">时间范围：</span>
                  <el-select  class="width-90" v-model="timeVal" @change="day_change" :placeholder="$t('msg.select')" clearable>
                     <el-option v-for="item in timeOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-date-picker v-if="timeShow === true" align="center"  class="status-time" v-model="dateValue" type="daterange" :picker-options="pickerOptions" :start-placeholder="$t('msg.start_date')" :range-separator="$t('msg.to')" :end-placeholder="$t('msg.end_date')" value-format="yyyy-MM-dd"></el-date-picker>
                  <!-- <el-date-picker v-if="timeShow === true" align="center"  class="status-time" v-model="dateValue" type="datetimerange" :picker-options="pickerOptions" :start-placeholder="$t('msg.start_date')" :range-separator="$t('msg.to')" :end-placeholder="$t('msg.end_date')" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker> -->
                  <el-button  class="m-bottom-5" type="primary" icon="el-icon-search" @click="search()"><span v-text="$t('msg.search')">搜索</span></el-button>
               </el-row>
               <div>
                  <el-table :data="statusData" style="width: 100%" :stripe="true" max-height="530">
                     <el-table-column property="number" :label="$t('msg.number')" min-width="100"></el-table-column>
                     <el-table-column property="time" :label="$t('msg.time')" min-width="150" sortable></el-table-column>
                     <el-table-column prop="status" :label="$t('msg.status')" min-width="150" sortable>
                        <template slot-scope="scope">
                           <span style="color: #67c23a;" v-if="scope.row.status === 1" v-text="$t('msg.online')">在线</span>
                           <span style="color: #F56C6C;" v-else v-text="$t('msg.offline')">离线</span>
                        </template>
                     </el-table-column>
                  </el-table>
               </div>
               <!-- 分页 -->
               <pagination :total="total" :page-size="pageSize" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
            </el-dialog>
         </el-form>
      </el-row>
   </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   export default {
      name: 'homeDeviceDetail',
      data () {
         return {
            userName: '',  // 会员名

            dev_24_status: '设备24小时状态图',
            sel_time_range: '请选择时间范围！',
            no_state: '暂无状态！',
            confirm: '确定',
            online_status_no_data: '设备24小时一直处于在线状态，无状态数据！',
            offline_status_no_data: '设备24小时一直处于离线状态，无状态数据！',
            open_s: '开',
            close_s: '关',
            yes: '是',
            no: '否',
            positive: '正',
            anti: '反',
            normal: '正常',
            abnormal: '异常',
            total: 0, // 表格数据总数
            pageSize: 0, // 每页显示数
            currPage: 1, // 当前页
            thisGatewayId: '',
            devType: '', // 设备类型(标记)
            devId: '', // 设备ID
            productId: '', // 类型ID
            devProductVal: '—', // 所属产品
            devTypeVal: '—', // 设备类型
            formatVal: '—', // 规格
            devModelVal: '—', // 设备型号
            devKeyVal: '—', // 设备Key
            devSecretVal: '—', // 设备秘钥
            devVersionVal: '—', // 设备版本
            descriptionVal: '—', // 型号描述
            agreementVal: '—', // 协议类型
            pushMessagesVal: '—', // 是否推送消息
            deviceAttach: false, // 面板
            panelstartVal: '', // 面板开始亮度
            panelendVal: '', // 面板结束亮度
            curDirectionVal: '', // 窗帘转动方向
            wheNormalVal: '', // 电量是否正常
            whePowerVal: '', // 是否推送电量
            macAddressVal: '—', // MAC地址
            locationVal: '—', // 位置
            deviceTypeId: '—', // 所属网关Id
            ownGatewayId: '—', // 所属网关Id
            ownGatewayVal: '—', // 所属网关
            GatewayVersion: '—', // 网关版本
            lastTimeVal: '—', // 最后在线时间
            offTimeVal: '—', // 最后离线时间
            firstAliasVal: '—', // 第一位别名
            secondAliasVal: '—', // 第二位别名
            thirdAliasVal: '—', // 第三位别名
            fourthAliasVal: '—', // 第四位别名
            firstStatusVal: '—', // 第一位状态
            secondStatusVal: '—', // 第二位状态
            thirdStatusVal: '—', // 第三位状态
            fourthStatusVal: '—', // 第四位状态
            numFlag: 0,
            switchFlag: 0, // 判断是否是开关
            switchVal: [], // 开光状态
            aliasArrVal: [],
            statusArrVal: [],
            devAliasVal: '—', // 设备别名
            addTimeVal: '—', // 入网时间
            onlineStatusVal: '—', // 在线状态
            thisStatus: 'OFFLINE', // 当前状态
            instruction: false, // 指令弹框
            infraredData: [], // 指令列表
            moreStatus: false, // 更多状态
            isChildren: 'false', // 是否是子设备
            timeVal: '24', // 时间范围
            dateValue: '', // 日期
            timeShow: false, // 日期框(显示状态)
            seartDate: '', // 开始日期
            endDate: '', // 结束日期
            timeOpt: [
               {
                  value: '24',
                  label: '24小时'
               },
               {
                  value: '3',
                  label: '3天'
               },
               {
                  value: '5',
                  label: '5天'
               },
               {
                  value: '7',
                  label: '7天'
               },
               {
                  value: '15',
                  label: '15天'
               },
               {
                  value: 'auto',
                  label: '自定义'
               }
            ],
            pickerOptions: {
               shortcuts: [{
                  // text: '最近一周',
                  onClick (picker) {
                     const end = new Date()
                     const start = new Date()
                     start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                     picker.$emit('pick', [start, end])
                  }
               }, {
                  // text: '最近一个月',
                  onClick (picker) {
                     const end = new Date()
                     const start = new Date()
                     start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                     picker.$emit('pick', [start, end])
                  }
               }, {
                  // text: '最近三个月',
                  onClick (picker) {
                     const end = new Date()
                     const start = new Date()
                     start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                     picker.$emit('pick', [start, end])
                  }
               }]
            },
            devData: [],
            controlData: [],
            baoziData: [],
            statusData: []
         }
      },
      mounted () {
         this.pageSize = sessionStorage.getItem('pageSize') * 1 // 每页显示数
         this.isChildren = sessionStorage.getItem('childDetail') // 是否是子设备
         let item1 = this.$echarts.init(document.getElementById('status-ech'))
         // 图表自适应
         window.onresize = function () {
            item1.resize() // 状态图表
         }
         this.userName = this.$route.query.userName
         this.device_info() // 设备信息
      },
      methods: {
         pageChange (v) {
            this.pageSize = v // 每页显示
            this.defaults.limit = this.pageSize
            this.more_status(0, 1)
         },
         currChange (v) { // 分页点击
            this.currPage = v // 当前页
            this.defaults.page = this.currPage
            this.defaults.limit = this.pageSize
            this.more_status(0, 1)
         },
         // 返回(设备管理)
         go_back () {
            sessionStorage.setItem('childDetail', 'false')
            // this.$router.push('/hotel_all?type=1')
            this.$router.back(-1)
         },
         // 返回(子设备)
         go_child_device () {
            sessionStorage.setItem('childDetail', 'false')
            let gatewayId = sessionStorage.getItem('gatewayId')
            this.$router.push('/device_child?id=' + gatewayId)
         },
         // 网关查询
         gateway_url () {
            let newpage = this.$router.resolve({
               name: 'device_gateway',
               query: {
                  id: this.ownGatewayId,
                  type: 2
               }
            })
            window.open(newpage.href, '_blank')
         },
         // 设备信息
         device_info () {
            let id = this.$route.query.id
            let t = this.$route.query.type
            let detUrl = urlObj.getHomeDevDetail
            this.devType = t
            this.devId = id
            let detParam = {
               deviceId: id
            }
            this.$axios.post(detUrl, detParam).then((res) => {
               if (res.success) {
                  let obj = res.records
                  this.devTypeVal = obj.versionsName
                  if (!obj.deviceNumber) {
                     this.devModelVal = 'HOS-GW-Pro1' // 设备型号
                  } else {
                     this.devModelVal = obj.deviceNumber
                  }
                  this.devProductVal = obj.productId
                  this.formatVal = obj.standard
                  this.devKeyVal = obj.productKey
                  this.devSecretVal = obj.deviceSecret
                  this.devVersionVal = obj.deviceVersions
                  this.descriptionVal = obj.versionsName
                  this.agreementVal = obj.protocolType
                  let deviceAttach = obj.deviceAttach // 面板
                  if (deviceAttach) {
                     this.deviceAttach = deviceAttach
                     if(deviceAttach.batteryAlarm){
                        this.wheNormalVal = deviceAttach.batteryAlarm // 电量是否正常
                     }
                     if(deviceAttach.isPushBattery){
                        this.whePowerVal = deviceAttach.isPushBattery // 是否推送电量
                     }
                     if(deviceAttach.direction){
                        this.curDirectionVal = deviceAttach.direction  // 窗帘转动方向
                     }
                  }
                  if (obj.syncChildRoom) {
                     this.syncDev = obj.syncChildRoom
                  }
                  this.pushMessagesVal = obj.isPush // 是否推送消息
                  this.locationVal = this.$route.query.location
                  this.deviceTypeId = obj.deviceTypeId
                  this.ownGatewayId = obj.gatewayId
                  this.ownGatewayVal = obj.gatewayName
                  this.GatewayVersion = obj.versions
                  this.macAddressVal = obj.deviceName
                  this.devAliasVal = obj.alias
                  this.addTimeVal = obj.createTime
                  this.onlineStatusVal = obj.state
                  this.lastTimeVal = obj.lastOnlineTime
                  this.offTimeVal = obj.lastOfflineTime
                  // 别名、状态
                  let productName = obj.productId.toString().substring(0, 6)
                  this.productId = productName
                  let switchs = false
                  if (productName === 'HOSWZB') {
                     switchs = true
                  } else {
                     switchs = false
                  }
                  let switchList = obj.switchList
                  if (switchList) {
                     let tempobj = {}
                     let tempArr = []
                     for (let i = 0; i < switchList.length; i++) {
                        tempobj.key_type = switchList[i].type
                        tempobj.key_alias = switchList[i].alias
                        tempobj.key_status = switchList[i].state
                        tempobj.whe_connect = switchList[i].isControlLight
                        tempArr.push(tempobj)
                        tempobj = {}
                     }
                     this.devData = tempArr
                  }
                  let controlList = []
                  if(obj.tempPanel){
                     controlList = [obj.tempPanel]
                  }
                  if (controlList) {
                     let tempobj = {}
                     let tempArr = []
                     for (let i = 0; i < controlList.length; i++) {
                        tempobj.mode = controlList[i].mode
                        tempobj.switch_statusr = controlList[i].onoff
                        tempobj.lock_status = controlList[i].lock
                        tempobj.current_temperature = controlList[i].curTemperature
                        tempobj.target_temperature = controlList[i].setTemperature
                        tempobj.wind_speed = controlList[i].fanSpeed
                        tempobj.time_on_time = controlList[i].timerOn
                        tempobj.time_off = controlList[i].timerOff
                        tempobj.reporting_time = controlList[i].updateTime
                        tempArr.push(tempobj)
                        tempobj = {}
                     }
                     this.controlData = tempArr
                  }
                  let infraredList = obj.infraredList
                  if (infraredList) {
                     let tempobj = {}
                     let tempArr = []
                     for (let i = 0; i < infraredList.length; i++) {
                        tempobj.id = infraredList[i].id
                        tempobj.dev_type = infraredList[i].childDeviceName
                        tempobj.dev_number = infraredList[i].childDeviceNum
                        tempobj.brand_name = infraredList[i].brand
                        tempobj.brand_id = infraredList[i].brandNum
                        tempobj.brand_model = infraredList[i].model
                        tempobj.add_time = infraredList[i].createTime
                        tempArr.push(tempobj)
                        tempobj = {}
                     }
                     this.baoziData = tempArr
                  }
                  this.thisStatus = obj.state // OFFLINE(0)：离线、ONLINE(1)：在线、2：未激活
                  if (!obj.stateList) {
                     obj.stateList = []
                  }
                  this.get_status(obj.stateList) // 获取状态
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 查看指令
         info (row) {
            this.instruction = true
            let detUrl = urlObj.devInfraredDeviceId
            let detParam = {
               infraredId: row.id
            }
            this.$axios.get(detUrl, detParam).then((res) => {
               if (res.success) {
                  let records = res.records
                  if (!records || records.length <= 0) {
                     this.infraredData = []
                     return
                  }
                  this.infraredData = records
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取状态
         get_status (stateList) {
            let arr = stateList
            if (!arr || arr.length <= 0) {
               let date = new Date()
               let currHours = (date.getHours() + 1) // 当前时间
               // 数组（小时）
               let hoursArr = [currHours]
               let statuVal = 29
               let statuArr = [29]
               if (this.thisStatus === "ONLINE") {
                  statuVal = 80
                  statuArr = [80]
               }
               for (var k = 0; k < 23; k++) {
                  if (currHours <= 0) {
                     currHours = 24
                  }
                  hoursArr.unshift(currHours - 1) // 追加到数组
                  statuArr.unshift(statuVal) // 追加到数组
                  currHours--
               }
               this.status_ech(hoursArr, statuArr) // 状态图表
               return
            }
            let timeArr = []
            let statArr = []
            let temp = ''
            for (var i = 0; i < arr.length; i++) {
               temp = arr[i].onOffTime.substring(11, arr[i].length)
               if (arr[i].state === "ONLINE") {
                  statArr.push(80)
               } else {
                  statArr.push(29)
               }
               timeArr.push(temp)
            }
            this.status_ech(timeArr, statArr) // 状态图表
         },
         // 状态图表
         status_ech (timeArr, statArr) {
            let item = this.$echarts.init(document.getElementById('status-ech'))
            item.setOption({
               title: {
                  text: this.dev_24_status,
                  textStyle: {
                     fontWeight: 400,
                     color: '#409eff'
                  },
                  x: 'center',
                  y: '10'
               },
               tooltip: {
                  trigger: 'axis',
                  borderColor: 'gray',
                  backgroundColor: 'rgba(255,255,255,0.8)',
                  borderWidth: '1',
                  textStyle: {
                     color: 'black'
                  },
                  // 自定义浮动框内容
                  formatter: function (params) {
                     let online = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1ABC9C"></span>'
                     let offline = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#ffa40d"></span>'
                     if (localStorage.lang === 'cn') {
                        if (params[0].data === 29) { // 离线
                           return params[0].name + '时' + '<br/>' + offline + '<span style="color:#ffa40d;"> 离线</span>'
                        } else if (params[0].data === 80) {
                           return params[0].name + '时' + ' <br/>' + online + '<span style="color:#069cfb;"> 在线</span>'
                        }
                     } else {
                        if (params[0].data === 29) { // 离线
                           return params[0].name + 'Hour' + '<br/>' + offline + '<span style="color:#ffa40d;"> Offline</span>'
                        } else if (params[0].data === 80) {
                           return params[0].name + 'Hour' + ' <br/>' + online + '<span style="color:#069cfb;"> Online</span>'
                        }
                     }
                  }
               },
               legend: {
                  data: ['ds'],
                  show: false
               },
               grid: {
                  left: '65',
                  right: '3%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  // data: ['12:01:55', '12:01:55', '12:01:55', '12:01:55', '12:01:55', '12:01:55', '12:01:55', '12:01:55', '12:01:55', '12:01:55']
                  data: timeArr
               },
               yAxis: {
                  type: 'value',
                  splitLine: {show: false}, // 去掉网格线
                  min: 0, // y轴最小值
                  max: 100, // y轴最大值
                  interval: 50, // 间隔
                  axisLabel: {
                     show: false // 刻度值
                  },
                  axisTick: {
                     show: false // 刻度
                  }
               },
               series: [
                  {
                     name: 'ds',
                     type: 'line',
                     stack: '总量',
                     // data: [29, 80, 29, 80, 29, 80, 29, 80, 29, 80, 29, 80],
                     data: statArr,
                     symbolSize: 10, // 设定实心点的大小
                     lineStyle: {
                        color: '#f61213',
                        type: 'dashed' // 线条样式
                     },
                     markLine: {
                        // symbol: 'none', // 去掉箭头
                        data: [
                           [
                              {name: '', x: '65', y: 102},
                              {name: '', x: '98%', y: 102},
                              {
                                 lineStyle: { // 警戒线的样式
                                    type: 'dashed',
                                    color: '#e5e5e5',
                                    width: 1
                                 }
                              }
                           ],
                           [
                              {name: '', x: '65', y: 210},
                              {name: '', x: '98%', y: 210},
                              {
                                 lineStyle: { // 警戒线的样式
                                    type: 'dashed',
                                    color: '#e5e5e5',
                                    width: 1
                                 }
                              }
                           ]
                        ]
                     }
                  }
               ]
            })
         },
         // 更多状态
         more_status (index, defaults) {
            this.day_change(this.timeVal)
            if (!this.timeVal) {
               this.seartDate = ''
               this.endDate = ''
            }
            this.moreStatus = true
            let staUrl = urlObj.getDevMoreStatusList
            let staParam = {}
            let staParams = {}
            if (defaults === 1) {
               staParam = this.defaults
               staParams = {
                  limit: this.defaults.limit, // 每页显示数
                  page: this.defaults.page, // 当前页
               }
            } else {
               staParams = {
                  limit: this.pageSize, // 每页显示数
                  page: this.currPage, // 当前页
               }
               staParam = {
                  limit: this.currPage, // 当前页
                  page: this.pageSize, // 每页显示数
                  // stTime: this.dateValue[0], // 开始日期
                  // edTime: this.dateValue[1], // 结束日期
                  stTime: this.seartDate, // 开始日期
                  edTime: this.endDate, // 结束日期
                  deviceId: this.devId // 设备ID
                  // deviceType: this.devType // 设备类型
               }
            }
            this.defaults = JSON.parse(JSON.stringify(staParam))
            this.$axios.post(staUrl, staParam).then((res) => {
               if (res.success) {
                  let records = res.records
                  // console.log(res)
                  if (records.length <= 0) {
                     this.currPage = 1
                     this.statusData = []
                     if (this.thisStatus === "ONLINE") {
                        this.$message({
                           showClose: true,
                           message: this.online_status_no_data,
                           type: 'info'
                        })
                     } else {
                        this.$message({
                           showClose: true,
                           message: this.offline_status_no_data,
                           type: 'info'
                        })
                     }
                     return
                  }
                  if (res.pageCount < this.currPage) {
                     this.currPage = res.pageCount
                  }
                  this.total = res.total
                  let td = {}
                  let tempArr = []
                  for (var i = 0; i < records.length; i++) {
                     td.number = this.currPage * this.pageSize - this.pageSize + i + 1 // 序号(当前页*每页显示数-每页显示数+索引+1)
                     td.time = records[i].onOffTime
                     td.status = records[i].state // 设备状态
                     tempArr.push(td)
                     td = {}
                  }
                  this.statusData = tempArr
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 时间范围
         day_change (e) {
            if (e === '24') {
               this.seartDate = this.get_day(-1) + this.get_hour() // 结束日期
            } else if (e === '3') {
               this.seartDate = this.get_day(-3) + this.get_hour() // 结束日期
            } else if (e === '5') {
               this.seartDate = this.get_day(-5) + this.get_hour() // 结束日期
            } else if (e === '7') {
               this.seartDate = this.get_day(-7) + this.get_hour() // 结束日期
            } else if (e === '15') {
               this.seartDate = this.get_day(-15) + this.get_hour() // 结束日期
            }
            if (e !== 'auto') {
               this.endDate = this.get_day(0) + this.get_hour() // 开始日期
            }
            if (e === 'auto') {
               this.timeShow = true
               this.seartDate = this.dateValue[0] // 开始日期
               this.endDate = this.dateValue[1] // 结束日期
            } else {
               this.timeShow = false
            }
         },
         get_day (num) {
            let date = new Date()
            let day = date.getTime() + 1000 * 60 * 60 * 24 * num
            date.setTime(day)
            let year = date.getFullYear()
            let month = date.getMonth()
            let tDate = date.getDate()
            month = this.get_month(month + 1)
            tDate = this.get_month(tDate)
            return year + '-' + month + '-' + tDate + ' '
         },
         get_month (month) {
            let m = month
            if (month.toString().length === 1) {
               m = '0' + month
            }
            return m
         },
         get_hour () {
            let date = new Date()
            let hour = date.getHours()
            let minute = date.getMinutes()
            let second = date.getSeconds()
            if (hour < 10) {
               hour = '0' + date.getHours()
            }
            if (minute < 10) {
               minute = '0' + date.getMinutes()
            }
            if (second < 10) {
               second = '0' + date.getSeconds()
            }
            return hour + ':' + minute + ':' + second
         },
         // 搜索
         search () {
            if (!this.timeVal && this.timeVal !== 'auto') {
               this.$alert(this.sel_time_range, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (this.timeVal === 'auto' && !this.dateValue) {
               this.$alert(this.sel_time_range, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            this.more_status()
         }
      }
   }
</script>
<style scoped lang="scss">
   .cont{
      min-width: 1120px;
      .box-span{
         vertical-align: middle;
         margin-left: 10px;
      }
   }
   .el-row{
      line-height: 30px;
      white-space: nowrap;
   }
   .header-right{
      float: right;
      width: 40%;
      text-align: right;
      p{
         display: inline-block;
         margin: 0 10px;
      }
      .box-pad{
         color: #00BFBF;
         border: 1px solid rgba(0, 191, 191, 1);
         background: rgba(0, 191, 191, 0.2);
         padding: 0 10px;
      }
   }
   .panel-box{
      border: 1px solid rgba(0, 178, 255, 1);
      padding: 5px;
      margin-right: 10px;
      background: rgba(0, 169, 242, 0.2);
      &.panel-box2{
         border: 1px solid rgba(112, 182, 3, 1);
         background: rgba(112, 182, 3, 0.2);
      }
   }

   .det-box{
      /* border: 1px solid red; */
      background: white;
      padding: 10px 20px;
      margin-bottom: 15px;
   }
   .det-box::after{
      content: "";
      display: block;
      clear: both;
   }
   .det-box > ul{
      list-style: none;
   }
   .det-box > ul > li{
      float: left;
      width: 25%;
      margin-bottom: 20px;
      font-size: 14px;
      white-space: nowrap;
   }
   .det-box > ul > li > span:first-child{
      display: inline-block;
      min-width: 140px;
      text-align: right;
   }
   // .ech-box{
   //   border: 1px solid red;
   //   margin-top: 15px;
   // }
   .ech-item{
      height: 300px;
      /* border: 1px solid red; */
      background: white;
      border-radius: 10px;
      position: relative;
      padding-bottom: 30px;
      padding-right: 30px;
   }
   .ech-item > .el-link{
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
   }
   #status-ech{
      height: 100%;
      width: 100%;
   }
   .txt-box{
      position: absolute;
      top: 46px;
      left: 12px;
      line-height: 110px;
   }
   .hour-box{
      position: absolute;
      right: 10px;
      bottom: 50px;
   }
   .gateway_url{
      color: #409EFF;
   }
   .gateway_url:hover{
      color: #1ABC9C;
      cursor: pointer;
      border-bottom: 1px solid #409EFF;
   }
</style>
